<template>
    <div class="bk">
        <div class="title">流程</div>
        <div v-if="!powerList.includes('1')">
            <div style="display:flex;justify-content: center;align-items: center;">
                <div style="width:35vw;display:flex;justify-content: left;align-items: center;">
                    <div style="width:1em;height:1em;background:#CBCBCB;border-radius: 50%;margin-right: 10px;"></div>
                    <van-badge :content="avatarList.length + defaultList.length" color="#1989fa">
                        <div style="font:600">审批人</div>
                    </van-badge>
                </div>
                <div style="flex: 1;display:flex;align-items: center;overflow-y: auto;">
                    <!-- 审核人列表空时显示基础样式 -->
                    <div v-if="avatarList.length == 0"
                        style="display:flex;align-items: center;flex-grow: 0; flex-shrink: 0">
                        <img class="avatar" src="../assets/icon/tj_img.png" alt="" @click="goToSelect('chooseList')">
                    </div>
                    <div v-for="(item, index) in avatarList" :key="item.value"
                        style="display:flex;align-items: center;flex-grow: 0; flex-shrink: 0">
                        <img v-show="index > 0" src="../assets/icon/jt4_icon.png" alt="">
                        <img v-if="item.avatar" class="avatar" :src="item.avatar" alt="" @click="goToSelect('chooseList')">
                        <div v-else class="avatar"
                            style="background:#307FFD;line-height: 3rem;color: #fff;font-size: 0.5em;"
                            @click="goToSelect('chooseList')">{{
                                item.name || item.userName
                            }}</div>
                    </div>
                    <div v-for="it in defaultList" :key="it.id"
                        style="display:flex;align-items: center;flex-grow: 0; flex-shrink: 0">
                        <img src="../assets/icon/jt4_icon.png" alt="">
                        <img v-if="it.avatar" class="avatar" :src="it.avatar" alt="">
                        <div v-else class="avatar"
                            style="background:#307FFD;line-height: 3rem;color: #fff;font-size: 0.5em;">{{
                                it.name || item.userName
                            }}</div>
                    </div>
                </div>
            </div>
            <div style="width:2px;height:35px;background:#CBCBCB;margin-left: 6px;" v-show="!isCollection">
            </div>
        </div>
        <div style="display:flex;justify-content: center;align-items: center;" v-show="!isCollection">
            <div style="width:35vw;display:flex;justify-content: left;align-items: center;">
                <div style="width:1em;height:1em;background:#CBCBCB;border-radius: 50%;margin-right: 10px;"></div>
                <van-badge :content="copyUserList.length + copyDefaultList.length" color="#1989fa">
                    <div style="font:600">抄送</div>
                </van-badge>
            </div>
            <div style="flex:1;display:flex;align-items: center;overflow-y: auto;">
                <div v-if="copyUserList.length == 0" style="display:flex;align-items: center;flex-grow: 0; flex-shrink: 0">
                    <img class="avatar" src="../assets/icon/tj_img.png" alt="" @click="goToSelect('copyList')">
                </div>
                <div v-for="(item, index) in copyUserList" :key="item.value"
                    style="display:flex;align-items: center;flex-grow: 0; flex-shrink: 0">
                    <img v-show="index > 0" src="../assets/icon/jt4_icon.png" alt="">
                    <img v-if="item.avatar" class="avatar" :src="item.avatar" alt="" @click="goToSelect('copyList')">
                    <div v-else class="avatar" style="background:#307FFD;line-height: 3rem;color: #fff;font-size: 0.5em;"
                        @click="goToSelect('copyList')">{{
                            item.name || item.userName
                        }}</div>
                </div>
                <div v-for="it in copyDefaultList" :key="it.id"
                    style="display:flex;align-items: center;flex-grow: 0; flex-shrink: 0">
                    <img src="../assets/icon/jt4_icon.png" alt="">
                    <img v-if="it.avatar" class="avatar" :src="it.avatar" alt="">
                    <div v-else class="avatar" style="background:#307FFD;line-height: 3rem;color: #fff;font-size: 0.5em;">{{
                        it.name || item.userName
                    }}</div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import Vue from 'vue';
import { configDetailList } from '../api/configDetail'
import { getConfig } from '../api/api'
// import { getUserAndDept } from '../api/dept'
import { getToken } from '../units/auth';
import { Popup, Cascader, Badge } from 'vant';

Vue.use(Popup);
Vue.use(Cascader)
Vue.use(Badge)
export default {
    name: 'process',
    props: {
        routerPath: {
            type: String,
            default: '/'
        },
        // 审核人列表
        avatarList: {
            typeof: Array,
            default: () => {
                return []
            }
        },
        //抄送人列表
        copyUserList: {
            typeof: Array,
            default: () => {
                return []
            }
        },
        distinguish: {
            //区分审核人是大美、中创或无项目
            typeof: Number,
            default: 3
        },
        isCollection: {
            typeof: Boolean,
            default: false
        }
    },
    data() {
        return {
            defaultList: [],//默认审核列表
            copyDefaultList: [],//默认抄送列表
            show: false,
            DMList: [], // 大美默认审核
            ZCList: [],//中创默认审核
            noPjList: [],//非项目审核
            collectionList: [],//收款列表
            powerList: [], //value 1总经理 2财务 3行政
        }
    },
    created() {
        this.powerList = JSON.parse(getToken("powerList") || "[]");
        this.getDetailList()
        this.getCopyDetailList()
    },
    watch: {
        'distinguish': {
            handler(newVal) {
                this.classification(newVal)
            }
        }
    },
    methods: {
        classification(newVal = this.distinguish) {
            if (!this.powerList.includes('1')) {
                console.log('new', newVal)
                switch (newVal) {
                    case 1:
                        this.defaultList = this.DMList
                        break
                    case 2:
                        this.defaultList = this.ZCList
                        break
                    default:
                        this.defaultList = this.isCollection ? this.collectionList : this.noPjList
                }
                this.$emit('judgeList', this.defaultList)
            }
        },
        async getDetailList() {
            if (this.powerList.includes('1')) {
                return
            }
            await configDetailList(1).then(res => {
                this.DMList = res.data.rows
            })
            await configDetailList(10).then(res => {
                this.ZCList = res.data.rows
            })
            await configDetailList(12).then(res => {
                this.noPjList = res.data.rows
            })
            await configDetailList(14).then(res => {
                this.collectionList = res.data.rows
            })
            this.classification()
        },

        async getCopyDetailList() {
            await getConfig(11).then(res => {
                this.copyDefaultList = res.data.rows
                this.$emit('copyList', this.copyDefaultList)
            })

        },
        goToSelect(val) {
            this.$router.push({ path: '/process', query: { routerPath: this.routerPath, type: val } })
        }
    }
}
</script>

<style lang="less" scoped>
.bk {
    width: 100vw;
    background: #fff;
    margin-bottom: 4vh;
    padding: 10px 16px;
    box-sizing: border-box;
}

.title {
    font-size: 1rem;
    font-weight: 600;
    text-align: left;
}

.avatar {
    width: 3rem;
    height: 3rem;
    margin: 0 10px;
}
</style>

