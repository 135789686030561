<template>
  <div class="bkColor">
    <headTitle :title="title" :routerPath="routerPath"></headTitle>
    <van-notify
      v-model="contentShow"
      :type="contentType"
      :style="{ marginTop: equipment ? '7vh' : 0 }"
    >
      <span>{{ content }}</span>
    </van-notify>
    <van-form validate-first @failed="onFailed" @submit="submitForm" ref="form">
      <van-field name="radio" label="是否项目报销" required>
        <template #input>
          <van-radio-group
            v-model="queryFrom.additional"
            direction="horizontal"
          >
            <van-radio :name="0">项目</van-radio>
            <van-radio :name="1">非项目</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <div v-if="queryFrom.additional == 0">
        <van-field
          clickable
          name="pjMsg"
          :value="pjMsg"
          label="项目信息"
          placeholder="点击选择项目"
          @click="projectShow = true"
          :rules="[{ required: true, message: '项目信息不能为空' }]"
          required
        />
        <van-popup v-model="projectShow" position="bottom">
          <div
            style="
              width: 100%;
              box-sizing: border-box;
              padding: 10px;
              display: flex;
              justify-content: space-between;
            "
          >
            <div @click="projectShow = false">取消</div>
            <div style="color: #576b95" @click="PjReimbursement">确认</div>
          </div>
          <van-search
            class="search"
            v-model="pjSearch"
            placeholder="请输入项目名称"
            shape="round"
            show-action
            @input="onPjSearch"
            @cancel="onPjCancel"
            action-text="清空"
          />
          <van-picker
            :columns="pjArrOption"
            ref="pjPicker"
            value-key="label"
            @cancel="projectShow = false"
          />
        </van-popup>
      </div>
      <van-field name="radio" label="报销单位（请以发票抬头一致）" required>
        <template #input>
          <van-radio-group v-model="queryFrom.belong" direction="horizontal">
            <van-radio :name="1">大美自然</van-radio>
            <van-radio :name="2">中创科技</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field label="填写人" :value="userMsg" readonly />
      <div class="chart">
        <div class="title">报销明细</div>
        <div style="overflow-x: auto; overflow-y: auto">
          <table class="table" :border="1" cellspacing="0">
            <tr>
              <td class="label">序号</td>
              <td class="label">费用类别</td>
              <td class="label">详细类别</td>
              <td class="label">金额（元）</td>
              <td class="label">发票数量</td>
              <td class="label">发票编号</td>
              <td class="label">报销时间开始和结束时间</td>
              <td class="label">备注</td>
              <td class="label">附件</td>
            </tr>
            <tr
              v-for="(item, index) in detailList"
              :key="index"
              @click="editList(index)"
            >
              <td class="label">{{ index + 1 }}</td>
              <td class="label">{{ item.reimbursementName }}</td>
              <td class="label">{{ item.detailTypeName }}</td>
              <td class="label">{{ item.price }}</td>
              <td class="label">{{ item.billNum }}</td>
              <td class="label">{{ item.billNo }}</td>
              <td class="label">{{ item.reimbursementTime }}</td>
              <td class="label">{{ item.description }}</td>
              <td class="label">
                <div
                  style="margin: 0 5px"
                  v-for="it in item.fileList"
                  :key="it.url"
                >
                  {{ it.fileName }}
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="btn">
        <van-button
          icon="plus"
          type="info"
          plain
          hairline
          size="small"
          @click="addData"
          native-type="button"
          >添加</van-button
        >
      </div>

      <!-- 弹出添加列表 -->
      <van-popup
        @close="keepFile"
        open
        v-model="pireType"
        position="bottom"
        :style="{ height: '80%', paddingTop: '40px', background: '#f6f6f6' }"
        closeable
      >
        <div
          style="
            text-align: left;
            margin-left: 10px;
            margin-bottom: 10px;
            color: #a4a4a4;
          "
        >
          当前编辑的是第{{ fileIndex + 1 }}行数据
        </div>
        <van-field
          clickable
          name="picker"
          :value="detailList[fileIndex].reimbursementName"
          label="费用类型"
          placeholder="点击选择费用类型"
          @click="typeShow = true"
          :rules="[{ required: true, message: '费用类型不能为空' }]"
          required
        />
        <van-popup v-model="typeShow" position="bottom">
          <van-picker
            show-toolbar
            :columns="payOption"
            value-key="label"
            @confirm="pyTypeConfirm"
            @cancel="typeShow = false"
          />
        </van-popup>

        <!-- <van-field v-if="queryFrom.additional == 0" v-model="detailList[fileIndex].detailTypeName" label="详细类别"
          placeholder="请输入" /> -->
        <!-- <div v-else> -->
        <van-field
          clickable
          name="picker"
          :value="detailList[fileIndex].detailTypeName"
          label="详细类型"
          placeholder="点击选择详细费用类型"
          @click="detailShow = true"
          :rules="[{ required: true, message: '详细类型不能为空' }]"
          required
        />
        <van-popup v-model="detailShow" position="bottom">
          <van-picker
            show-toolbar
            :columns="detailOption"
            value-key="label"
            @confirm="detailTypeConfirm"
            @cancel="detailShow = false"
          />
        </van-popup>
        <!-- </div> -->

        <van-field
          v-model="detailList[fileIndex].price"
          type="number"
          label="金额（元）"
          placeholder="请输入"
          :rules="[{ required: true, message: '金额不能为空' }]"
          required
        />
        <van-field
          v-model="detailList[fileIndex].billNum"
          type="digit"
          label="发票数量"
          placeholder="请输入"
          name="pattern"
          :rules="[
            { required: true, message: '数量不能为空' },
            { pattern, message: '请输入不能大于10' },
          ]"
          required
        />
        <div v-if="detailList[fileIndex].billNum > 0">
          <van-field
            v-for="(item, index) in Array.from(
              { length: detailList[fileIndex].billNum },
              (_, i) => i
            )"
            :key="index"
            label="发票编号"
            v-model="fields[index]"
            placeholder="请输入"
          />
        </div>
        <van-field
          v-model="startTime"
          is-link
          readonly
          label="报销开始时间"
          placeholder="请选择报销开始时间"
          @click="startShow = true"
          :rules="[{ required: true, message: '报销开始时间不能为空' }]"
          required
        />
        <van-popup v-model="startShow" round position="bottom">
          <van-datetime-picker
            v-model="currentDate"
            type="date"
            title="报销开始时间"
            @confirm="chooseTime('s')"
            @cancel="startShow = false"
          />
        </van-popup>
        <van-field
          v-model="endTime"
          is-link
          readonly
          label="报销结束时间"
          placeholder="请选择报销结束时间"
          @click="endShow = true"
          :rules="[{ required: true, message: '报销结束时间不能为空' }]"
          required
        />
        <van-popup v-model="endShow" round position="bottom">
          <van-datetime-picker
            v-model="currentDate"
            type="date"
            title="报销结束时间"
            @confirm="chooseTime('e')"
            @cancel="endShow = false"
          />
        </van-popup>

        <van-field
          v-model="detailList[fileIndex].description"
          label="备注"
          placeholder="请输入"
        />

        <van-field name="uploader" label="附件">
          <template #input>
            <van-uploader
              v-model="uploader"
              @click-preview="viewFile"
              accept="file"
              upload-icon="plus"
              :after-read="afterRead"
              :before-read="beforeRead"
              @delete="deleteFile"
              :preview-full-image="false"
            />
          </template>
        </van-field>

        <div
          style="
            width: 100vw;
            margin-top: 20px;
            padding: 20px 0;
            background: #fff;
            display: flex;
            justify-content: space-around;
          "
        >
          <van-button
            round
            block
            color="#CCCCCC"
            native-type="button"
            style="width: 35vw"
            @click="detailListChange('del')"
            :disabled="uploading"
            >删除</van-button
          >
          <van-button
            round
            block
            type="info"
            native-type="button"
            style="width: 35vw"
            @click="detailListChange('add')"
            :disabled="uploading"
            >添加</van-button
          >
        </div>
      </van-popup>

      <van-field
        v-model="queryFrom.totalPrice"
        label="合计"
        placeholder="请输入"
      />
      <van-field
        v-model="queryFrom.reason"
        rows="1"
        autosize
        label="报销说明"
        type="textarea"
        :rules="[{ required: true, message: '报销说明不能为空' }]"
        required
      />
      <process
        @judgeList="getJudgeList"
        @copyList="getCopyList"
        :avatarList="selectList"
        :copyUserList="copyUserList"
        :routerPath="'/newPjReimbursement'"
        :distinguish="belong"
      ></process>
      <van-button
        round
        block
        type="info"
        native-type="submit"
        :loading="isLoading"
        loading-text="加载中..."
        >提交</van-button
      >
    </van-form>
  </div>
</template>

<script>
import headTitle from "../components/headTitle.vue";
import Vue from "vue";
import {
  Field,
  Cell,
  Popup,
  DatetimePicker,
  Picker,
  Uploader,
  Button,
  Toast,
  Form,
  Notify,
  Search,
  RadioGroup,
  Radio,
} from "vant";

Vue.use(Field);
Vue.use(Cell);
Vue.use(Popup);
Vue.use(DatetimePicker);
Vue.use(Picker);
Vue.use(Uploader);
Vue.use(Button);
Vue.use(Toast);
Vue.use(Form);
Vue.use(Notify);
Vue.use(Search);
Vue.use(Radio);
Vue.use(RadioGroup);

import { infoList, getInfo } from "../api/info";
import { getToken } from "../units/auth";
import { getConfig, fileUpload } from "../api/api";
// import { configDetailList } from '../api/configDetail'
import { reimbursementAdd } from "../api/reimbursement";
import process from "../components/process.vue";
import { timeChange } from "../units/units";

export default {
  name: "newPjReimbursement",
  components: {
    headTitle,
    process,
    [Field.name]: Field,
  },
  data() {
    return {
      title: "新建项目报销",
      pjMsg: "", //项目信息显示文字
      pjOption: [], //项目选项
      pjArrOption: [], //项目存放数组
      pjSearch: "", //项目筛选值
      userMsg: "", //用户显示文字
      projectShow: false,
      typeShow: false,
      detailShow: false,
      startShow: false,
      endShow: false,
      currentDate: new Date(),
      startTime: "", //开始时间
      endTime: "", //结束时间
      payOption: [], //费用类型选项
      detailOption: [], //详细类别选项
      uploader: [], //附件
      pireType: false,
      belong: 1, //项目归属
      selectArr: [], //默认选择审核人数组
      selectList: [], //选择完审核人后的返回
      // flag: [],//装载已选审核人列表
      copyUserArr: [], //抄送人默认列表
      copyUserList: [], //抄送人列表
      clear: false,
      detailList: [
        {
          price: "", //报销金额
          reimbursementType: "", //费用类别(数字)
          reimbursementName: "", //费用名称(字符串)
          reimbursementTime: "", //费用时间
          detailType: "", //详细类型(数字)
          detailTypeName: "", //详细类别(字符串)
          billNo: "", //发票编号
          billNum: 0, //发票数量
          description: "", //备注
          fileList: [], //文件列表
        },
      ],
      queryFrom: {
        projectId: null,
        belong: 1, //项目归属
        totalPrice: 0,
        detailList: [],
        additional: 0, //判断是否非项目的报销
        projectReviewedList: [], //审核人列表
        copyList: [], //抄送人列表
        reason: "",
      },
      fileIndex: 0, //当前列表序号
      fileData: [], //文件列表载体
      isAdd: false,
      fields: [],
      routerPath: "/pjReimbursement",
      equipment: false, //客户端判断
      contentShow: false, //是否显示提醒
      content: "", //通知内容
      contentType: "warning",
      prohibit: false,
      pattern: /^[0-9]{1}(\.[0-9]{0,2})?$/,
      uploading: false,
      // powerList: [], //value 1总经理 2财务 3行政
      isLoading: false,
    };
  },
  mounted() {
    // this.powerList = JSON.parse(getToken("powerList") || "[]");
    this.isMobile();
    // this.title = this.queryFrom.additional == 0 ? "新建项目报销" : "新建报销";
    this.routerPath = this.$route.query.route
      ? this.$route.query.route
      : "/pjReimbursement";

    this.getPjOption();
    this.getPayType();
    this.getDetailType();
    this.getToken();
    // this.getSecectList();
    // this.getCopyUserList();
    // console.log('detailList', this.detailList)
  },
  watch: {
    detailList: {
      handler(newVal) {
        console.log("xiugaile", newVal);
        console.log(this.isAdd);
        this.queryFrom.totalPrice = 0;
        newVal.forEach((it) => {
          this.queryFrom.totalPrice =
            it.price * 1 + this.queryFrom.totalPrice * 1;
        });
      },
      deep: true,
    },
    "queryFrom.additional": {
      handler(newly) {
        console.log("0.0", newly);
        console.log(this.isAdd);
        if (newly == 1) {
          this.belong = 3;
          this.queryFrom.projectId = null;
          this.pjMsg = "";
        } else {
          this.belong = this.queryFrom.belong;
        }
        if (this.isAdd == false) {
          this.fileIndex = 0;
          this.detailList = [
            {
              price: "", //报销金额
              reimbursementType: "", //费用类别(数字)
              reimbursementName: "", //费用名称(字符串)
              reimbursementTime: "", //费用时间
              detailType: "", //详细类型(数字)
              detailTypeName: "", //详细类别(字符串)
              billNo: "", //发票编号
              billNum: 0, //发票数量
              description: "", //备注
              fileList: [], //文件列表
            },
          ];
        }

        this.isAdd = false;
        let time = this.detailList[0].reimbursementTime.split("~") || "";
        this.startTime = time[0] || "";
        this.endTime = time[1] || "";
        this.getPayType();
        this.getDetailType();
      },
    },
    "queryFrom.belong": {
      handler(newly) {
        if (this.queryFrom.additional == 0) {
          this.belong = newly;
        }
      },
    },
  },
  beforeRouteEnter(to, from, next) {
    if (from.path == "/reimbursementDetails") {
      next((vm) => {
        if (vm.$route.query.detail) {
          let obj = JSON.parse(vm.$route.query.detail);
          delete obj.projectReviewedList;
          delete obj.copyList;
          for (let key in vm.queryFrom) {
            vm.queryFrom[key] = obj[key];
            console.log(key);
          }

          //     this.queryFrom.additional = this.$route.query.additional
          // ? this.$route.query.additional * 1
          // : 0;
          vm.belong = vm.queryFrom.additional == 0 ? obj.belong : 3;
          vm.detailList = vm.queryFrom.detailList;
          vm.userMsg = obj.userName;
          vm.pjMsg = obj.projectName;
          vm.isAdd = true;
        }
      });
    }
    next();
  },
  activated() {
    this.title = this.queryFrom.additional == 0 ? "新建项目报销" : "新建报销";
    // if (this.$route.query.detail) {
    //   let obj = JSON.parse(this.$route.query.detail)
    //   delete obj.projectReviewedList
    //   delete obj.copyList
    //   for (let key in this.queryFrom) {
    //     this.queryFrom[key] = obj[key]
    //     console.log(key)
    //   }
    //   this.belong = this.queryFrom.additional == 0 ? obj.belong : 3
    //   this.detailList = this.queryFrom.detailList
    //   this.userMsg = obj.userName
    //   this.pjMsg = obj.projectName
    //   this.isAdd = true
    // }
    this.getSecectList();
    this.getCopyUserList();
  },
  beforeDestroy() {
    //收尾操作，销毁
    window.localStorage.removeItem("chooseList");
    window.localStorage.removeItem("copyList");
  },
  methods: {
    isMobile() {
      this.equipment = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
        ? false
        : true;
    },
    getToken() {
      this.userMsg = getToken("userName");
    },

    getPjOption() {
      infoList().then((res) => {
        let obj = res.data.rows.map((it) => {
          return {
            label: it.projectName,
            value: it.id,
            belong: it.belong,
          };
        });
        this.pjOption = this.pjArrOption = obj;
      });
    },
    onPjSearch(val) {
      this.pjArrOption = this.pjOption.filter((it) => {
        if (it.label.indexOf(val) != -1) {
          return it;
        }
      });
    },
    onPjCancel() {
      this.pjArrOption = this.pjOption;
    },
    //项目信息选择
    PjReimbursement() {
      this.projectShow = false;
      this.prohibit = false;
      this.queryFrom.projectId = this.$refs.pjPicker.getValues()[0].value;
      this.pjMsg = this.$refs.pjPicker.getValues()[0].label;
      switch (this.$refs.pjPicker.getValues()[0].belong) {
        case "DM":
          this.belong = 1;
          break;
        case "ZC":
          this.belong = 2;
          break;
        default:
          this.belong = 3;
      }
      getInfo(this.queryFrom.projectId).then((res) => {
        this.contentShow = false;
        if (
          res.data.data.budgetPercent >= 70 &&
          res.data.data.budgetPercent < 80
        ) {
          // Notify({ type: 'danger', message: '该项目支出已达到预警值，请注意控制支出' });
          this.content = "项目准备进入预警状态";
          this.contentType = "warning";
          this.contentShow = true;
        } else if (
          res.data.data.budgetPercent >= 80 &&
          res.data.data.budgetPercent < 100
        ) {
          this.content = `已支出费用占成本预算的${res.data.data.budgetPercent}%，请注意控制支出`;
          this.contentType = "danger";
          this.contentShow = true;
        } else if (res.data.data.budgetPercent >= 100) {
          this.content = `支出费用已超出预算，无法付款，请申请增加预算`;
          this.contentType = "danger";
          this.contentShow = true;
          this.prohibit = true;
        }
      });
    },
    //获取报销类型
    getPayType() {
      if (this.queryFrom.additional == 1) {
        getConfig(9).then((res) => {
          let obj = res.data.rows.map((it) => {
            return {
              label: it.name,
              value: it.value,
              id: it.id,
            };
          });
          this.payOption = obj;
        });
      } else {
        getConfig(3).then((res) => {
          let obj = res.data.rows.map((it) => {
            return {
              label: it.name,
              value: it.value,
            };
          });
          this.payOption = obj;
        });
      }
      this.queryFrom.reimbursementName = "";
      this.queryFrom.reimbursementType;
    },
    pyTypeConfirm(val) {
      this.typeShow = false;
      this.detailList[this.fileIndex].reimbursementType = val.value;
      this.detailList[this.fileIndex].reimbursementName = val.label;
      if (val.id) {
        this.getDetailType("&parentId=" + val.id);
      }
    },
    getDetailType(id = "") {
      getConfig(5 + id).then((res) => {
        let obj = res.data.rows.map((it) => {
          console.log(it.name);
          return {
            label: it.name,
            value: it.value,
          };
        });
        this.detailOption = obj;
      });
    },
    detailTypeConfirm(val) {
      this.detailShow = false;
      this.detailList[this.fileIndex].detailType = val.value;
      this.detailList[this.fileIndex].detailTypeName = val.label;
    },

    //添加按钮
    addData() {
      if (this.isAdd == false) {
        this.pireType = true;
        return;
      }
      let temp = {
        price: "", //报销金额
        reimbursementType: "", //费用类别
        reimbursementName: "", //费用名称
        reimbursementTime: "", //费用时间
        detailType: "", //详细类型
        detailTypeName: "", //详细类型字符串
        billNo: "", //发票编号
        billNum: 0, //发票数量
        description: "", //备注
        fileList: [], //文件列表
      };
      this.detailList.push(temp);
      this.startTime = "";
      this.endTime = "";
      this.fileIndex = this.detailList.length - 1;
      this.pireType = true;
    },
    //添加弹框关闭触发
    keepFile() {
      if (this.uploading) {
        return;
      }
      this.fields = [];
      if (
        this.detailList.length > 1 &&
        this.detailList[this.fileIndex].reimbursementType == "" &&
        this.detailList[this.fileIndex].price == "" &&
        this.detailList[this.fileIndex].billNum == "0" &&
        this.startTime == "" &&
        this.endTime == ""
      ) {
        this.detailList.splice(this.fileIndex, 1);
        this.fileIndex = 0;
      }
      // this.detailListChange('add')
      let time = this.detailList[0].reimbursementTime.split("~");
      this.startTime = time[0] || "";
      this.endTime = time[1] || "";
    },
    //文件图片上传时校验
    beforeRead(file) {
      if (file.type == 'application/msword') {
                Toast('请将.doc类型文件另存为改成.docx类型的文件后再进行上传（请注意:手动改后缀可能文件类型并没有变化）')
                return
            }
      //上传之前校验
      if (file.size > 30 * 1024 * 1024) {
        Toast("只允许上传30M的文件");
        return false;
      }
      return true;
    },
    //文件上传成功之后
    async afterRead(file) {
      file.status = "uploading";
      file.message = "上传中...";
      this.uploading = true;
      //文件读取完成后的回调函数
      let fd = new FormData();
      fd.append("file", file.file);
      await fileUpload(fd)
        .then((res) => {
          if (res.data.code == 200) {
            this.uploader[this.uploader.length - 1].url = res.data.url; //给返回的文件添加url属性
            this.uploader[this.uploader.length - 1].fileName =
              res.data.originalName;
            this.fileData[this.fileIndex] = this.uploader; //把当前的this.uploader存进列表里
            file.status = "";
            file.message = ""; //上传状态
            this.uploading = false;
          } else {
            file.status = "failed";
            file.message = "上传失败";
          }
        })
        .catch((err) => {
          if (err) {
            file.status = "failed";
            file.message = "上传失败";
            this.uploading = false;
          }
        });
    },
    //删除文件
    deleteFile() {
      this.fileData[this.fileIndex] = this.uploader;
    },
    //文件预览
    viewFile(file) {
      if (file.file) {
        if (
          file.url.indexOf(".jpg") !== -1 ||
          file.url.indexOf(".jpeg") !== -1 ||
          file.url.indexOf(".png") !== -1
        ) {
          return;
        }
      }
      this.$router.push({
        path: "/preview",
        query: { url: file.url, routerPath: "/newPjReimbursement" },
      });
    },
    //默认审核人流程
    getJudgeList(val) {
      this.selectArr = val;
    },
    //获取已选人的列表
    getSecectList() {
      this.selectList =
        JSON.parse(window.localStorage.getItem("chooseList")) || [];
    },
    getCopyList(val) {
      this.copyUserArr = val;
    },
    getCopyUserList() {
      this.copyUserList =
        JSON.parse(window.localStorage.getItem("copyList")) || [];
    },
    //校验不通过
    onFailed(errorInfo) {
      Toast.fail(errorInfo.errors[0].message);
    },
    submitForm() {
      //对已选择的审核人不同的数据进行整合
      let selectAll = JSON.parse(JSON.stringify(this.selectList))
        .concat(this.selectArr)
        .reverse();
      this.queryFrom.projectReviewedList = selectAll.map((it, idx) => {
        return {
          approvalSequence: idx,
          userId: it.userId || it.commonValue,
        };
      });

      let copyAll = JSON.parse(JSON.stringify(this.copyUserList))
        .concat(this.copyUserArr)
        .reverse();
      this.queryFrom.copyList = copyAll.map((it) => {
        return {
          userId: it.userId || it.commonValue,
        };
      });

      this.queryFrom.detailList = this.detailList;
      if (
        this.queryFrom.detailList.length == 1 &&
        this.detailList[0].reimbursementType == "" &&
        this.detailList[0].price == "" &&
        this.detailList[0].billNum == "0" &&
        this.startTime == "" &&
        this.endTime == ""
      ) {
        Toast.fail("报销明细不能为空");
        return;
      }
      this.isLoading = true;
      reimbursementAdd(this.queryFrom)
        .then((res) => {
          if (res.data.code == 200) {
            this.isLoading = false;
            Toast.success("提交成功");
            this.$router.replace(this.routerPath);
            //this.$destroy()
          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    //添加详细按钮的操作
    detailListChange(f) {
      if (f == "add") {
        if (
          this.detailList[this.fileIndex].reimbursementType == "" ||
          this.detailList[this.fileIndex].price == "" ||
          this.detailList[this.fileIndex].billNum == "" ||
          this.startTime == "" ||
          this.endTime == ""
        ) {
          Toast.fail("必填项不能为空");
          return;
        }
        this.isAdd = true;
        if (this.fileData[this.fileIndex]) {
          this.detailList[this.fileIndex].fileList = this.fileData[
            this.fileIndex
          ].map((it) => {
            return {
              url: it.url,
              fileName: it.fileName,
            };
          });
        }
        this.detailList[this.fileIndex].billNo = this.fields.toString();
        this.fields = [];

        // this.fileData.push(this.uploader)
        let f = this.fileData[this.fileIndex] ? 1 : 0; //1替换0是插入
        this.fileData.splice(this.fileIndex, f, this.uploader); //从this.fileIndex插入

        this.uploader = [];
      } else if (f == "del") {
        this.startTime = "";
        this.endTime = "";
        if (this.detailList.length == 1) {
          this.detailList = [
            {
              price: "", //报销金额
              reimbursementType: "", //费用类别
              reimbursementName: "", //费用名称
              reimbursementTime: "", //费用时间
              detailType: "", //详细类型
              detailTypeName: "", //详细类型字符串
              billNo: "", //发票编号
              billNum: 0, //发票数量
              description: "", //备注
              fileList: [], //文件列表
            },
          ];
          return;
        }
        this.detailList.splice(this.fileIndex, 1);
        this.fileData.splice(this.fileIndex, 1);
        this.fileIndex = 0;
      }
      this.pireType = false;
    },
    //列表选择回显
    editList(index) {
      this.fileIndex = index;
      this.uploader = this.fileData[this.fileIndex] || [];

      this.fields = this.detailList[this.fileIndex].billNo.split(",") || [];
      let time = this.detailList[this.fileIndex].reimbursementTime.split("~");
      this.startTime = time[0];
      this.endTime = time[1];
      this.pireType = true;
    },
    chooseTime(t) {
      if (t == "s") {
        this.startTime = timeChange(this.currentDate, true);
        this.startShow = false;
      } else {
        this.endTime = timeChange(this.currentDate, true);
        this.endShow = false;
      }
      this.detailList[this.fileIndex].reimbursementTime =
        this.startTime + "~" + this.endTime;
    },
  },
};
</script>

<style lang="less" scoped>
.bkColor {
  width: 100vw;
  height: 100vh;
  background: #f6f6f6;
}

/deep/ .van-cell {
  flex-wrap: wrap;
  margin-bottom: 2vh;
}

/deep/ .van-field__label,
.van-cell__title {
  width: 100vw;
  font-size: 1rem;
}

/deep/ .van-cell__title {
  -webkit-box-flex: unset;
  -webkit-flex: unset;
  flex: unset;
  text-align: left;
}

/deep/ .van-cell__value {
  text-align: left;
}

.search /deep/ .van-cell {
  margin-bottom: unset;
}

.chart {
  width: 100vw;
  // height: 100%;
  padding: 16px;
  box-sizing: border-box;
  background: #fff;
}

.chart .title {
  height: 5vh;
  font-size: 1rem;
  text-align: left;
  line-height: 5vh;
}

.chart .table {
  // display: flex;
  overflow-x: auto;
  // white-space: nowrap;
  border: #e4e5e7;
  table-layout: fixed;
}

.chart .label {
  min-width: 150px;
  height: 5vh;
}

.btn {
  height: 7vh;
  margin-bottom: 2vh;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>